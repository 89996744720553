var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.modal.filtro)?_c('Filtro',{attrs:{"filtro":_vm.modal},on:{"filtro":_vm.carregarFiltro,"filtroFechar":_vm.fecharFiltro}}):_vm._e(),(_vm.detmodal)?_c('Detalhes',{attrs:{"consulta":_vm.objeto},on:{"detclose":_vm.fecharDetalhes}}):_vm._e(),_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-icon',[_vm._v(" fas fa-clock ")]),_c('v-toolbar-title',{staticClass:"pl-4"},[_vm._v(" Relatório de Análise para Reclamação Correios ")]),_c('v-spacer'),_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.modal.filtro = true}}},[_c('v-icon',[_vm._v("fas fa-search")])],1),(this.todos_clientes == true)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-app-bar-nav-icon',_vm._g({on:{"click":_vm.generateFileAll}},on),[_c('v-icon',[_vm._v("fas fa-upload")])],1)]}}],null,false,489967560)},[_c('span',[_vm._v("Gerar Arquivo Todos Clientes")])]):_vm._e(),(_vm.grid.objetos.totalRegistros > 0 || _vm.selected.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-app-bar-nav-icon',_vm._g({on:{"click":_vm.generateFile}},on),[_c('v-icon',[_vm._v("fas fa-file-upload")])],1)]}}],null,false,1176253058)},[(this.typereclama == 'abertura')?_c('span',[_vm._v("Gerar Arquivo Reclamação")]):_c('span',[_vm._v("Gerar Arquivo Reativação")])]):_vm._e(),_c('v-app-bar-nav-icon',{on:{"click":_vm.fechar}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"px-6",attrs:{"cols":"12"}},[_c('v-tabs',{attrs:{"color":"#fff","slider-color":"red","flat":"","active-class":"active"},model:{value:(_vm.tabOrd),callback:function ($$v) {_vm.tabOrd=$$v},expression:"tabOrd"}},_vm._l((_vm.tabDat),function(item){return _c('v-tab',{key:item.value,on:{"click":function($event){return _vm.getSearch(item.value)}}},[_vm._v(" "+_vm._s(item.tab)+" ")])}),1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"px-6 py-6"},[_c('v-data-table',{staticClass:"elevation-1 pt-4",attrs:{"dense":"","headers":_vm.grid.objetos.cabecalho,"items":_vm.grid.objetos.items,"items-per-page":_vm.grid.objetos.porPagina,"hide-default-footer":"","loading":_vm.grid.objetos.carregando,"item-key":"codigo","show-select":"","fixed-header":""},scopedSlots:_vm._u([{key:"item.numero_rastreio",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"none"},on:{"click":function($event){return _vm.carregarDetalhes(item)}}},[_vm._v(" "+_vm._s(item.numero_rastreio)+" ")])]}},_vm._l((_vm.grid.objetos.cabecalho.filter(function (header) { return header.hasOwnProperty('formatter'); }
              )),function(header){return {key:("item." + (header.value)),fn:function(ref){
              var header = ref.header;
              var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}})],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"fab":"","small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.voltarTopo()}}},[_c('v-icon',[_vm._v(" fas fa-arrow-up ")])],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-pagination',{attrs:{"length":Math.ceil( _vm.grid.objetos.totalRegistros / this.grid.objetos.porPagina ),"total-visible":5},on:{"input":_vm.mudarPagina},model:{value:(_vm.grid.objetos.paginaAtual),callback:function ($$v) {_vm.$set(_vm.grid.objetos, "paginaAtual", $$v)},expression:"grid.objetos.paginaAtual"}})],1),_c('v-col',{staticClass:"px-6 py-6",staticStyle:{"padding-bottom":"0px !important"},attrs:{"cols":"2"}},[_c('v-select',{attrs:{"dense":"","outlined":"","headers":_vm.grid.objetos.cabecalho,"label":"Resultados por página","items":_vm.grid.objetos.qtdsPaginacao,"item-text":"text","item-value":"value"},model:{value:(_vm.grid.objetos.porPagina),callback:function ($$v) {_vm.$set(_vm.grid.objetos, "porPagina", $$v)},expression:"grid.objetos.porPagina"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>

    <!-- Início filtro -->
    <Filtro
      v-if="modal.filtro"
      v-on:filtro="carregarFiltro"
      v-on:filtroFechar="fecharFiltro"
      :filtro="modal"
    />
    <!-- Fim filtro -->

    <!-- Início detalhes -->
    <Detalhes
      v-if="detmodal"
      v-on:detclose="fecharDetalhes"
      :consulta="objeto"
    />
    <!-- Fim detalhes -->

    <!-- Início principal -->
    <v-card>
      <v-toolbar flat>
        <v-icon> fas fa-clock </v-icon>
        <v-toolbar-title class="pl-4">
          Relatório de Análise para Reclamação Correios
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="modal.filtro = true">
          <v-icon>fas fa-search</v-icon>
        </v-app-bar-nav-icon>
        <v-tooltip bottom v-if="this.todos_clientes == true" >
          <template v-slot:activator="{ on }">
            <v-app-bar-nav-icon @click="generateFileAll" v-on="on" >
              <v-icon>fas fa-upload</v-icon>
            </v-app-bar-nav-icon>
          </template>
          <span>Gerar Arquivo Todos Clientes</span>
        </v-tooltip>
        <!-- <v-app-bar-nav-icon @click="generateFileAll" v-if="this.todos_clientes == true" >
          <v-icon>fas fa-upload</v-icon>
        </v-app-bar-nav-icon> -->

        <v-tooltip bottom v-if="grid.objetos.totalRegistros > 0 || selected.length > 0" >
          <template v-slot:activator="{ on }">
            <v-app-bar-nav-icon
              @click="generateFile"
              v-on="on"
            >
              <v-icon>fas fa-file-upload</v-icon>
            </v-app-bar-nav-icon>
          </template>
          <span v-if="this.typereclama == 'abertura'">Gerar Arquivo Reclamação</span>
          <span v-else>Gerar Arquivo Reativação</span>
        </v-tooltip>
        <!-- <v-app-bar-nav-icon
          @click="generateFile"
          v-if=" (modal.generateFile = true && (this.grid.objetos.totalRegistros > 0 || this.selected.length > 0 ) ) "
        >
          <v-icon>fas fa-file-upload</v-icon>
        </v-app-bar-nav-icon> -->

        <v-app-bar-nav-icon @click="fechar">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>

      </v-toolbar>

      <v-divider></v-divider>
      <v-card-text>

        <v-row>
          <!-- <v-col class="px-6 py-6"> -->
          <v-col cols="12" class="px-6">
            <v-tabs
              color="#fff"
              v-model="tabOrd"
              slider-color="red"
              flat
              active-class="active"
            >
              <v-tab v-for="item in tabDat"
                :key="item.value"
                @click="getSearch(item.value)"
              >
                {{ item.tab }}
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="px-6 py-6">
            <v-data-table
              dense
              :headers="grid.objetos.cabecalho"
              :items="grid.objetos.items"
              :items-per-page="grid.objetos.porPagina"
              hide-default-footer
              class="elevation-1 pt-4"
              :loading="grid.objetos.carregando"
              item-key="codigo"
              v-model="selected"
              show-select
              fixed-header
            >
              <!-- v-model="grid.objetos.itemSelecionado" -->
              <template #item.numero_rastreio="{ item }">
                <a @click="carregarDetalhes(item)" style="text-decoration: none" >
                  {{ item.numero_rastreio }}
                </a>
              </template>
              <!-- This template looks for headers with formatters and executes them -->
              <template
                v-for="header in grid.objetos.cabecalho.filter((header) =>
                  header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ header, value }"
              >
                {{ header.formatter(value) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <!-- inicio paginacao -->
        <v-row class="mt-4">
          <v-col cols="2">
            <v-btn
              fab
              small
              color="primary"
              dark
              @click="voltarTopo()"
              style="margin-left: 10px"
            >
              <v-icon> fas fa-arrow-up </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8">
            <v-pagination
              v-model="grid.objetos.paginaAtual"
              :length="Math.ceil( grid.objetos.totalRegistros / this.grid.objetos.porPagina )"
              @input="mudarPagina"
              :total-visible="5"
            ></v-pagination>
          </v-col>
          <v-col cols="2" class="px-6 py-6" style="padding-bottom: 0px !important" >
            <v-select
              dense
              outlined
              :headers="grid.objetos.cabecalho"
              label="Resultados por página"
              :items="grid.objetos.qtdsPaginacao"
              item-text="text"
              item-value="value"
              v-model="grid.objetos.porPagina"
            ></v-select>
          </v-col>
        </v-row>
        <!-- Final paginacao -->

      </v-card-text>
    </v-card>
    <!-- Fim principal -->

  </div>
</template>

<script>
import { baseApiUrl, showSuccess, showError } from "@/global";
import axios from "axios";
import moment from "moment";
import Template from "../components/Template.vue";
import Filtro from "../components/objetos/frmfiltro.vue";
import Detalhes from "../components/objetos/frmdetalhes.vue";

export default {
  components: {
    Template,
    Filtro,
    Detalhes,
  },
  name: "AnaliseReclamacoes",
  data: () => ({
    codigo_empresa: '',
    todos_clientes: (window.localStorage.getItem("actRead") == "true"),

    tabOrd: 0,
    tabDat: [
      { tab: "Abertura", content: "abertura", value: 1 },
      { tab: "Reativação", content: "reativacao", value: 2 },
    ],
    typereclama: "abertura",
    selected: [],

    grid: {
      objetos: {
        cabecalho: [
          { text: "#", value: "codigo", sortable: false },
          { text: "Rastreio", value: "numero_rastreio", sortable: false },
          { text: "PI", value: "protocolo_retorno", sortable: false },
          { text: "Dt. Prev", value: "data_prevista", formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), sortable: false, },
          { text: "Tipo", value: "tipo", sortable: false },
          { text: "Status", value: "status", sortable: false },
          { text: "Dt. Mov.", value: "data", formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, sortable: false, },
          { text: "Movimentação", value: "descricao", sortable: false },
          { text: "Motivo", value: "motivo", sortable: false },
          { text: "Modo", value: "modo", sortable: false },
          { text: "Inclusão", value: "cadastrado_em", formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, sortable: false, },
        ],
        items: [],
        carregando: false,
        paginaAtual: 1,
        totalRegistros: 0,
        // reclama: [
        //   { text: "ABERTURA", value: "abertura" },
        //   { text: "RE ABERTURA", value: "reabertura" },
        // ],
        qtdsPaginacao: [10, 15, 50, 100],
        porPagina: 100,
        itemSelecionado: [],
        coluna: "reclamacoes.codigo",
        direcao: "asc",
        ordenacao: {
          colunas: [
            { text: "Código", value: "reclamacoes.codigo" },
            { text: "Rastreio", value: "reclamacoes.numero_rastreio" },
            { text: "PI", value: "reclamacoes.protocolo_retorno" },
            { text: "Dt. Prev", value: "objetos.data_prevista" },
            { text: "Tipo", value: "reclamacoes.tipo" },
            { text: "Status", value: "reclamacoes.status" },
            { text: "Dt. Mov.", value: "reclamacoes.data" },
            { text: "Movimentacao", value: "relamacoes.descricao" },
            { text: "Motivo", value: "reclamacoes.motivo" },
            { text: "Modo", value: "reclamacoes.modo" },
            { text: "Inclusão", value: "reclamacoes.cadastrado_em" },
          ],
          direcao: [
            { text: "ASC", value: "asc" },
            { text: "DESC", value: "desc" },
          ],
        },
      },
      detalhes: {
        cabecalho: [
          { text: "Status", value: "descricao", sortable: false },
          { text: "Data", value: "data_hora", sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, },
          { text: "Origem", value: "origem_cidade_uf_local", sortable: false },
          { text: "Destino", value: "destino_cidade_uf_local", sortable: false, },
        ],
        items: [],
        carregando: false,
      },
      reclama: {
        cabecalho: [
          { text: "Status", value: "descricao", sortable: false },
          { text: "Data", value: "data_hora", sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, },
          { text: "Origem", value: "origem_cidade_uf_local", sortable: false },
          { text: "Destino", value: "destino_cidade_uf_local", sortable: false, },
        ],
        items: [],
        carregando: false,
      },
    },

    modal: {
      filtro: false,
      name: "Analise Reclamações",
      dt_considerada: false,
      dt_prevista: false,
      dt_entrega: false,
      dt_ultima_posicao: false,
      rastreio: false,
      detalhes: false,
      loading: false,
      cod_transportadora: true,
      data_inicio: false,
      data_final: false,
      cod_servico: true,
    },
    filtros: {
      codigo: "",
      cod_transportadora: "",
      numero_rastreio: "",
      prazo: "",
      dt_considerada: "",
      dt_prevista: "",
      dt_entrega: "",
      cep_origem: "",
      cep_destino: "",
      dt_ultima_posicao: "",
      ultima_posicao: "",
      data_inicio: null,
      data_final: null,
      cod_servico: "",
    },

    objetoAtual: "",
    filtro: "",
    rastreios: [],
    detalhes: [],
    loading: false,
    detalhes: {
      enviado: {
        data_postagem: "",
        data_considerada: "",
        cep: "",
        cidade: "",
      },
      para: {
        nome: "",
        cep: "",
        cidade: "",
        bairro: "",
        rua: "",
      },
      previsao_de_entrega: {
        data: "",
        em_domicilio: "",
      },
      entregue_em: {
        data: "",
      },
    },
    objeto: {
      numero_rastreio: "",
    },
    detmodal: false,
  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
  },
  mounted() {
    this.carregarObjetos();
  },
  methods: {
    limparFiltros() {
      this.filtros.codigo = "";
      this.filtros.cod_transportadora = "";
      this.filtros.numero_rastreio = "";
      this.filtros.prazo = "";
      this.filtros.dt_considerada = "";
      this.filtros.dt_prevista = "";
      this.filtros.dt_entrega = "";
      this.filtros.cep_origem = "";
      this.filtros.cep_destino = "";
      this.filtros.dt_ultima_posicao = "";
      this.filtros.ultima_posicao = "";
      this.filtros.cod_servico = "";
    },
    fecharFiltro() {
      this.modal.filtro = false;
    },
    carregarFiltro( paramFiltro ) {
      this.modal.filtro = false;
      this.filtros = paramFiltro;
      this.carregarObjetos();
    },
    fechar() {
      this.$router.push("/");
    },
    fecharDetalhes() {
      this.detmodal = false;
      this.objeto = {
        item: {},
        detalhes: false,
        habilita: false,
        aba_rastreio: true,
      };
    },
    getSearch( value ) {
      if ( value == 1 ) {
        this.typereclama = "abertura";
      }
      else if ( value == 2 ) {
        this.typereclama = "reabertura";
      }
      reabertura
      this.carregarObjetos();
    },

    btnStopReclama(rastreio) {
      if ( rastreio != 0)
      {
        let resultado = confirm("Deseja interromper a reclamação para o objeto: " + rastreio + " ?");
        let allObj = '';
        if (resultado) {
          resultado = confirm("Interromper outros objetos com a mesma situação ?");
          allObj = "N";
          if (resultado) {
            allObj = "S";
            alert("Todos os objetos com a mesma situação serão interrompidos");
          } else {
            alert("Interromper a reclamação do objeto: " + rastreio);
          }
        }
      }
      else
      {
        let retQuestion = false;
        this.$swal({
          title: 'ENCERRAR RECLAMAÇÃO',
          text: `Encerrar reclamação do(s) objeto(s) selecionados(s)?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          confirmButtonColor: '#4BB543',
          cancelButtonColor: '#d33',
          allowOutsideClick: false,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.itensSelecionados = [];
            for (let i = 0; i < this.selected.length; i++) {
              this.itensSelecionados.push({ codigo: this.selected[i].codigo, numero: this.selected[i].numero_rastreio })
            }
            let url = `${baseApiUrl}/stop-reclamacoes`;
            axios
              .post(url, {
                    data: { ide: this.codigo_empresa, ids: this.itensSelecionados}
                })
              .then((response) => {
                retQuestion = response.data.retorno;
                if (retQuestion) {
                  this.selected = [];
                  this.$swal({
                    title: 'Sucesso!!!',
                    text: 'Encerramento concluído com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#4BB543',
                    allowOutsideClick: true,
                  });
                } else {
                  // console.log(response.data.mensagem);
                  this.$swal({
                    title: 'Falha!!!',
                    text: response.data.mensagem,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#4BB543',
                    allowOutsideClick: true,
                  });
                }
              })
              .finally(() => {
                this.itensSelecionados = [];
                if (retQuestion) {
                  this.carregarObjetos();
                }
              });
            }
        });
      }
    },
    carregarObjetos() {
      this.selected = [];
      this.grid.objetos.items = [];
      this.modal.detalhes = false;
      this.modal.reclama = false;
      this.grid.objetos.carregando = true;
      this.modal.generateFile = false;

      let url = `${baseApiUrl}/analise`;
      url += `?GerarFile=N`;
      url += `&page=${this.grid.objetos.paginaAtual}`;
      url += `&coluna=${this.grid.objetos.coluna}`;
      url += `&direcao=${this.grid.objetos.direcao}`;
      url += `&type=${this.typereclama}`;
      url += `&cod_empresa=${this.codigo_empresa}`;

      if (Number(this.grid.objetos.porPagina) > 0)
        url += `&por_pagina=${this.grid.objetos.porPagina}`;
      if (this.filtros.codigo != "")
        url += `&codigo=${this.filtros.codigo}`;
      if (this.filtros.numero_rastreio != "")
        url += `&numero_rastreio=${this.filtros.numero_rastreio}`;
      if (this.filtros.dt_prevista != "")
        url += `&data_prevista=${this.filtros.dt_prevista}`;

      axios
        .get(url)
        .then((res) => {
          if (res.data.retorno) {
            this.grid.objetos.items = res.data.dados.data;
            this.grid.objetos.totalRegistros = res.data.dados.total;
            this.grid.objetos.porPagina = Number(res.data.dados.per_page);
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          this.grid.objetos.carregando = false;
          this.modal.loading = false;
        });

        // this.grid.objetos.carregando = false;
    },
    mudarPagina(pagina) {
      this.grid.objetos.paginaAtual = pagina;
      this.carregarObjetos();
    },
    voltarTopo() {
      window.scrollTo(0, 0);
    },
    voltarBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
    formatarNumeroBR(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatarDataBR(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/yyyy");
      }
    },
    carregarDetalhes(item) {
      this.objeto.item = item;
      this.objeto.item.cod_empresa = this.codigo_empresa;
      this.objeto.habilita = Number(item.protocolo_retorno) > 0;
      this.objeto.detalhes = true;
      this.detmodal = true;
      this.modal.loading = false;
    },
    salvarProtocoloReclamacao() {
      this.objeto.item = item;
      this.objeto.habilita = Number(item.protocolo_retorno) > 0;
      this.objeto.detalhes = true;
      this.detmodal = true;
      this.modal.loading = false;
    },
    generateFileAll() {
      this.$swal({
        title: 'RECLAMAÇÃO TODOS CLIENTES',
        text: `Gerar o arquivo de todos os clientes?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
        }
      });
    },
    generateFile() {

      this.$swal({
        title: 'ARQUIVO RECLAMAÇÃO',
        text: `Gerar o arquivo?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          let texto = "";
          if ( !this.selected.length ) {
            texto = (this.grid.objetos.totalRegistros > 1200) ? 'Será criado o arquivo com os primeiros 1200 objetos' : 'Será criado o arquivo com '+`${this.grid.objetos.totalRegistros}`+' objetos listados';
          } else {
            texto = (this.selected.length > 1200) ? 'Será criado o arquivo 1200 objetos' : 'Será criado o arquivo com '+`${this.selected.length}`+' objetos selecionados';
          }
          this.$swal({
            title: 'ARQUIVO RECLAMAÇÃO',
            text: texto,
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
          this.itensSelecionados = [];
          for (let i = 0; i < this.selected.length; i++) {
            this.itensSelecionados.push({ codigo: this.selected[i].codigo, numero: this.selected[i].numero_rastreio, instru: this.selected[i].motivo })
          }

          let url = `${baseApiUrl}/file-analise`;
          axios
            .post(url, {
                data: { ide: this.codigo_empresa, idg: "S", idt: this.typereclama, ids: this.itensSelecionados }
            })
            .then((res) => {
              if (res.data.retorno) {
                const fileName = res.data.filename;
                var old = JSON.stringify(res.data.data).replace(/null/g, '""');
                var newArray = JSON.parse(old);
                var opts = {
                  headers: true,
                  columns: [
                    { columnid: "CONTRATO", cell: { style: "" } },
                    { columnid: "CARTAO", cell: { style: "" } },
                    { columnid: "NOMEDESTINATARIO", cell: { style: "" } },
                    { columnid: "TELEFONEREMETENTE", cell: { style: "" } },
                    { columnid: "CODIGOREGISTRO", cell: { style: "" } },
                    { columnid: "EMAILREMETENTE", cell: { style: "" } },
                    { columnid: "MOTIVO", cell: { style: "" } },
                    { columnid: "EMBALAGEM", cell: { style: "" } },
                  ],
                };
                if (this.typereclama == "reabertura") {
                  opts = {
                    headers: true,
                    columns: [
                      { columnid: "CONTRATO", cell: { style: "" } },
                      { columnid: "CARTAO", cell: { style: "" } },
                      { columnid: "PROTOCOLO", cell: { style: "" } },
                      { columnid: "TIPOMANIFESTACAO", cell: { style: "" } },
                      { columnid: "MOTIVOREATIVACAO", cell: { style: "" } },
                      { columnid: "OBSERVACAO", cell: { style: "" } },
                    ],
                  };
                }
                alasql('SELECT * INTO XLSX("' + fileName + '", ?) FROM ?', [ opts, newArray, ]);

                this.selected = [];
                this.$swal({
                  title: 'Sucesso!!!',
                  text: 'Arquivo '+fileName+' gerado.',
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#4BB543',
                  allowOutsideClick: true,
                });
              } else {
                console.log(res.data.mensagem);
                this.$swal({
                  title: 'Falha!!!',
                  text: res.data.mensagem,
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#4BB543',
                  allowOutsideClick: true,
                });
              }
            })
            .catch((error) => {
              // showError(error);
              this.$swal({
                title: 'Falha!!!',
                text: error,
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#4BB543',
                allowOutsideClick: true,
              });
            })
            .finally(() => {
              this.itensSelecionados = [],
              this.carregarObjetos()
            });
        }
      });
    },
  },
  watch: {
    // "grid.objetos.typereclama": {
    //   handler: function (val, oldVal) {
    //     this.carregarObjetos();
    //   },
    // },
    "grid.objetos.porPagina": {
      handler: function (val, oldVal) {
        if (val != oldVal) {
          if (val > 0) {
            this.grid.objetos.porPagina = Number(val);
            this.mudarPagina(1);
          } else {
            this.grid.objetos.porPagina = 10;
            this.mudarPagina(1);
          }
        }
      },
    },
    "grid.objetos.coluna": {
      handler: function (val, oldVal) {
        if (val != oldVal) {
          this.carregarObjetos();
        }
      },
    },
    "grid.objetos.direcao": {
      handler: function (val, oldVal) {
        this.carregarObjetos();
      },
    },
    "reclamacoes.numero_rastreio": {
      handler: function (val, oldVal) {
        if (val == null || val == "") {
          this.objetoAtual = "";
          this.objeto.numero_rastreio = "";
          this.objeto.protocolo_retorno = "";
          this.grid.detalhes.items = [];
          this.detalhes.enviado.data_postagem = "";
          this.detalhes.enviado.data_considerada = "";
          this.detalhes.enviado.cep = "";
          this.detalhes.enviado.cidade = "";
          this.detalhes.para.nome = "";
          this.detalhes.para.cep = "";
          this.detalhes.para.cidade = "";
          this.detalhes.para.bairro = "";
          this.detalhes.para.rua = "";
          this.detalhes.previsao_de_entrega.data = "";
          this.detalhes.previsao_de_entrega.em_domicilio = "";
          this.detalhes.entregue_em.data = "";
        }
      },
    },
    "objeto.numero_rastreio": {
      handler: function (val, oldVal) {
        if (val == null || val == "") {
          this.objetoAtual = "";
          this.objeto.numero_rastreio = "";
          this.detalhes.enviado.data_postagem = "";
          this.detalhes.enviado.data_considerada = "";
          this.detalhes.enviado.cep = "";
          this.detalhes.enviado.cidade = "";
          this.detalhes.para.nome = "";
          this.detalhes.para.cep = "";
          this.detalhes.para.cidade = "";
          this.detalhes.para.bairro = "";
          this.detalhes.para.rua = "";
          this.detalhes.previsao_de_entrega.data = "";
          this.detalhes.previsao_de_entrega.em_domicilio = "";
          this.detalhes.entregue_em.data = "";
          this.grid.detalhes.items = [];
        }
      },
    },
  },
};
</script>

<style scoped>
.active {
  background-color: #018656;
  color: #fff;
}
</style>
